import {
  SET_ARTICLES_DATA,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAILURE,
} from './constants';

export const setArticlesData = props => ({
  type: SET_ARTICLES_DATA,
  ...props,
});

export const fetchArticlesRequest = () => ({
  type: FETCH_ARTICLES_REQUEST,
});

export const fetchArticlesSuccess = ({ articles, page, totalCount }) => ({
  type: FETCH_ARTICLES_SUCCESS,
  articles,
  page,
  totalCount,
});

export const fetchArticlesFailure = () => ({
  type: FETCH_ARTICLES_FAILURE,
});

export const fetchArticles = page => async dispatch => {
  dispatch(fetchArticlesRequest());

  await fetch(`/food/api/articles?page=${page}`)
    .then(response => response.json())
    .then(json => dispatch(fetchArticlesSuccess(json)))
    .catch(() => dispatch(fetchArticlesFailure()));
};
