import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageHeader from '../generic/PageHeader';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import { promoShape } from '../promo/shapes';
import Pagination from '../generic/pagination/Pagination';
import PaginationSummary from '../generic/pagination/PaginationSummary';
import LoadingOverlay from '../generic/LoadingOverlay';
import FocusTarget from '../generic/FocusTarget';
import ErrorMessage from '../generic/message/ErrorMessage';
import { scrollIntoView } from '../generic/scrollIntoView';
import { fetchArticles } from './actions';
import MetaProperties from '../generic/MetaProperties';

const PAGE_SIZE = 24;

export class Page extends Component {
  focusRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchArticles();
      scrollIntoView(this.focusRef.current);
      this.focusRef.current.focus();
    }
  }

  fetchArticles = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page'), 10) || 1;
    this.props.fetchArticles(page);
  };

  render() {
    const { articles, page, totalCount, loading, error } = this.props;
    return (
      <>
        <MetaProperties
          title="Stories"
          description="BBC Food brings you food and nutrition insights and stories from around the UK."
        />
        <PageHeader className="index__title" title="Recent food stories" />
        <LoadingOverlay active={loading}>
          {!error ? (
            <>
              <FocusTarget ref={this.focusRef} />
              {totalCount > 0 && (
                <PaginationSummary page={page} pageSize={PAGE_SIZE} totalCount={totalCount} />
              )}
              <StandardCollection12Promos
                promos={articles}
                id="articles-collection"
                maxCollectionSize={PAGE_SIZE}
              />
              {totalCount > PAGE_SIZE && (
                <Pagination
                  clientSide
                  page={page}
                  pages={Math.ceil(totalCount / PAGE_SIZE)}
                  buildUrl={p => `/food/articles?page=${p}`}
                />
              )}
            </>
          ) : (
            <div className="gel-wrap">
              <ErrorMessage retry={this.fetchArticles} />
            </div>
          )}
        </LoadingOverlay>
      </>
    );
  }
}

Page.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  fetchArticles: PropTypes.func,
  location: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

Page.defaultProps = {
  fetchArticles: () => {},
  loading: false,
  error: false,
  page: 1,
  totalCount: 0,
};

export default connect(
  ({ articlesIndexReducer }) => ({
    articles: articlesIndexReducer.articles,
    page: articlesIndexReducer.page,
    totalCount: articlesIndexReducer.totalCount,
    loading: articlesIndexReducer.loading,
    error: articlesIndexReducer.error,
  }),
  {
    fetchArticles,
  }
)(Page);
